// 根据组织架构列表生成组织架构树(树形结构)
const genOrgTree = (orgList, orgTree = []) => {
  if (!Array.isArray(orgList) || !orgList.length) return orgTree;

  const clonedOrgList = JSON.parse(JSON.stringify(orgList));

  clonedOrgList.forEach(org => {
    const { pid } = org;

    if (!pid) {
      orgTree.push(org);

      return;
    }

    const pOrg = clonedOrgList.find(org => org._id === pid);

    if (!pOrg) return;

    const { children = [] } = pOrg;

    pOrg.children = [...children, org];
  });

  return orgTree;
};

export default genOrgTree;
