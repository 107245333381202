<!-- org -->
<template>
  <el-cascader
    class="org"
    v-model="selected"
    :options="formattedOrgTree"
    :props="cascadeOptions"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :show-all-levels="showAllLevels"
    :separator="separator"
    :collapse-tags="collapseTags"
    :filterable="filterable"
    :filter-method="filterMethod"
    :debounce="debounce"
    :size="size"
    @change="handleValueChange"
  />
</template>

<script>
  import cascade from '../../mixins/cascade';
  import genOrgTree from '../../lib/gen-org-tree';
  import { getOrg } from '../../api/common';

  // 递归格式化各个节点
  const recursiveFormat = (nodes, keyMaps) => {
    if (!Array.isArray(nodes) || !nodes.length) return [];

    const {
      labelKey,
      valueKey,
      childrenKey,
      disabledKey,
      disabledCallback,
      hiddenCallback,
    } = keyMaps;

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      // 根据 hiddenCallback 选项判断是否隐藏
      if (hiddenCallback instanceof Function && hiddenCallback(node)) {
        nodes.splice(i, 1);
        i--;

        continue;
      }

      const { _id, name = '', children = [] } = node;
      const formattedChildren = recursiveFormat(children, keyMaps);
      const formattedNode = {
        [labelKey]: name,
        [valueKey]: _id,
        [childrenKey]: formattedChildren.length ? formattedChildren : null,
        [disabledKey]: false,
      };
      const combinedNode = {
        ...node,
        ...formattedNode,
      };

      // 根据 disabledCallback 选项判断是否禁用
      combinedNode[disabledKey] =
        disabledCallback instanceof Function
          ? disabledCallback(combinedNode)
          : false;

      nodes[i] = combinedNode;
    }

    return nodes;
  };

  export default {
    name: 'org',
    mixins: [cascade],
    data() {
      return {
        orgList: [],
      };
    },
    computed: {
      // 级联选择器配置对象
      cascadeOptions() {
        const {
          multiple,
          checkStrictly,
          emitPath,
          labelKey,
          valueKey,
          childrenKey,
          disabledKey,
          expandTrigger,
        } = this;

        return {
          multiple,
          checkStrictly,
          emitPath,
          label: labelKey,
          value: valueKey,
          children: childrenKey,
          disabled: disabledKey,
          expandTrigger,
        };
      },
      orgTree() {
        return genOrgTree(this.orgList);
      },
      formattedOrgTree() {
        const {
          orgTree,
          labelKey,
          valueKey,
          childrenKey,
          disabledKey,
          disabledCallback,
          hiddenCallback,
        } = this;

        return Array.isArray(orgTree) && orgTree.length
          ? recursiveFormat(orgTree, {
              labelKey,
              valueKey,
              childrenKey,
              disabledKey,
              disabledCallback,
              hiddenCallback,
            })
          : [];
      },
      clonedOrgList() {
        return JSON.parse(JSON.stringify(this.orgList));
      },
      clonedOrgTree() {
        return JSON.parse(JSON.stringify(this.orgTree));
      },
    },
    mounted() {
      this.initOrgTree();
    },
    methods: {
      // 刷新数据，供父组件调用
      async refresh() {
        return await this.getOrgList();
      },
      async getOrgList() {
        const res = await getOrg();

        if (!res) return false;

        this.orgList = res.data || [];

        return true;
      },
      // 初始化资源树
      async initOrgTree() {
        const success = await this.getOrgList();

        if (!success) return;

        this.$emit('on-ready', this.clonedOrgTree, this.clonedOrgList);
      },
      // 选中项改变
      handleValueChange(value) {
        this.$emit('input', value);
        this.$emit('on-change', value, this.clonedOrgTree, this.clonedOrgList);
      },
    },
  };
</script>

<style scoped lang="scss">
  .org {
    width: 100%;
  }
</style>
