<!-- schedule-edit -->
<template>
  <el-dialog
    class="schedule-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="schedule-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="95px"
      @submit.native.prevent
    >
      <el-form-item label="主播名称:" prop="signId">
        <!-- 编辑排期 -->
        <template v-if="!isEdit">
          <sign
            v-if="open"
            v-model="model.signId"
            :status="cooperationStatusEnum.signed"
            enable-data-permission
          />
        </template>

        <!-- 新增排期 -->
        <template v-else>
          <el-input v-model="model.anchorName" disabled />
        </template>
      </el-form-item>

      <el-form-item label="合作方式:" prop="cooperationType">
        <cooperation-type
          v-model="model.cooperationType"
          :disabled="isSchedulePublished"
        />
      </el-form-item>

      <el-form-item label="首播时间:" prop="firstShowTime">
        <el-date-picker
          v-model="model.firstShowTime"
          :disabled="isSchedulePublished"
          placeholder="请选择"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>

      <el-form-item label="推广链接名:" prop="promoteLink">
        <el-input v-model="model.promoteLink" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="QQ群:" prop="contactNo">
        <el-input v-model="model.contactNo" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="带玩运营:">
        <org v-model="model.playOperatorId" @on-ready="handleOrgReady" />
      </el-form-item>

      <el-form-item label="跟进运营:">
        <org v-model="model.operatorId" @on-ready="handleOrgReady" />
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="schedule-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消
        </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
          >确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapState } from 'vuex';
  import Sign from '../../../../components/sign';
  import CooperationType from '../../../../components/cooperation-type';
  import Org from '../../../../components/org';
  import cooperationTypes from '../../../../enum/cooperation-type.js';
  import cooperationStatus from '../../../../enum/cooperation-status.js';
  import { format } from '../../../../util/date.js';
  import { isPositiveIntString } from '../../../../util/validator.js';
  import {
    getSchedule,
    addSchedule,
    updateSchedule,
  } from '../../../../api/anchor/schedule';

  // QQ 群校验
  const contactNoValidator = (rule, value, callback) => {
    isPositiveIntString(value) && value.length >= 5
      ? callback()
      : callback(new Error('请填写有效的 QQ 号'));
  };

  export default {
    name: 'schedule-edit',
    components: {
      Sign,
      CooperationType,
      Org,
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // 组织架构列表
        orgList: null,
        // 当前编辑的排期对象
        schedule: null,
        model: null,
        rules: {
          signId: [{ required: true, message: '请选择', trigger: 'change' }],
          cooperationType: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          firstShowTime: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          promoteLink: [
            { required: true, message: '请填写', trigger: 'change' },
          ],
          contactNo: [
            { required: true, message: '请填写', trigger: ' change' },
            { validator: contactNoValidator, trigger: 'change' },
          ],
        },
        loading: false,
        saving: false,
      };
    },
    computed: {
      ...mapState('anchor/schedule', ['scheduleSignId']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.scheduleSignId;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}排期`;
      },
      // 首播时间组件的配置选项
      firstShowTimeDatePickerOptions() {
        return {
          disabledDate: time => format(time) < format(Date.now()),
        };
      },
      // 合作状态枚举值
      cooperationStatusEnum() {
        return cooperationStatus.enum;
      },
      // 排期状态是否是'已投放'
      isSchedulePublished() {
        return this.schedule?.status === cooperationStatus.enum.published;
      },
      // 当前选中的带玩运营的名称
      selectedPlayOperatorName() {
        const orgList = this.orgList;

        if (!orgList || !orgList.length) return '';

        const { playOperatorId } = this.model;
        const matchedOrg = orgList.find(item => item._id === playOperatorId);

        return matchedOrg?.name || '';
      },
      // 当前选中的跟进运营的名称
      selectedOperatorName() {
        const orgList = this.orgList;

        if (!orgList || !orgList.length) return '';

        const { operatorId } = this.model;
        const matchedOrg = orgList.find(item => item._id === operatorId);

        return matchedOrg?.name || '';
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const {
          signId = '',
          anchorName = '',
          cooperationType = cooperationTypes.enum.unofficial,
          firstShowTime = '',
          promoteLink = '',
          contactNo = '',
          playOperatorId = '',
          operatorId = '',
        } = data || {};

        this.model = {
          signId,
          anchorName,
          cooperationType,
          firstShowTime,
          promoteLink,
          contactNo,
          playOperatorId,
          operatorId,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          playOperatorName: this.selectedPlayOperatorName,
          operatorName: this.selectedOperatorName,
        };
        const method = this.isEdit ? updateSchedule : addSchedule;
        const res = await method(params);

        return !!res;
      },
      // 获取排期对象详情
      async getSchedule() {
        const res = await getSchedule(this.scheduleSignId);

        if (!res) return false;

        this.schedule = res.data;

        return true;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getSchedule();

        this.loading = false;

        if (!success) return;

        this.initModel(this.schedule);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.schedule = null;

        this.resetModel();
      },
      // 组织架构组件就绪
      handleOrgReady(orgTree, orgList) {
        if (this.orgList) return;

        this.orgList = orgList;
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .schedule-edit {
    .schedule-edit-form {
      .el-select,
      .el-input,
      .el-date-time-editor {
        width: 100%;
      }
    }
  }
</style>
